import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100vh;
  width: 100vh;
  justify-content: center;
  align-items: center;
`;

const BigText = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
`;

const MidText = styled.h2`
  font-size: 1.5rem;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
`;

const SmallText = styled.div`
  font-size: 1rem;
  font-weight: light;
  font-family: 'Roboto', sans-serif;
`;

export const NotFound = () => (
  <Container>
    <BigText>404</BigText>
    <MidText>Oops! This Page Could Not Be Found</MidText>
    <SmallText>
      Sorry but the page you are looking for does not exist, have been removed.
      name changed or is temporarily unavailable
    </SmallText>
  </Container>
);

export default NotFound;
