import React from 'react';
import PropTypes from 'prop-types';
import Quizzes from '../components/quizzes';
import { VERSIONS } from '../constants';

const renderQuiz = (uuid, version) => {
  let resolvedQuiz = null;

  switch (version) {
    case VERSIONS.v1:
      resolvedQuiz = <Quizzes.QuizV1 uuid={uuid} />;
      break;
    case VERSIONS.v3:
      resolvedQuiz = <Quizzes.QuizV3 uuid={uuid} />;
      break;
    case VERSIONS.v4:
      resolvedQuiz = <Quizzes.QuizV4 uuid={uuid} />;
      break;

    default:
      resolvedQuiz = <Quizzes.QuizV1 uuid={uuid} />;
      break;
  }
  return resolvedQuiz;
};

export const QuizResolver = ({ uuid, version }) => (
  <>{renderQuiz(uuid, version)}</>
);

QuizResolver.propTypes = {
  uuid: PropTypes.string,
  version: PropTypes.string,
};

QuizResolver.defaultProps = {
  uuid: '',
  version: '',
};

export default QuizResolver;
