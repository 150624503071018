import React from 'react';
import PropTypes from 'prop-types';
import LandingPage from '../components/LandingPage';

export const LandingPageResolver = ({ uuid }) => <LandingPage uuid={uuid} />;

LandingPageResolver.propTypes = {
  uuid: PropTypes.string,
};

LandingPageResolver.defaultProps = {
  uuid: '',
};

export default LandingPageResolver;
