import React from 'react';
import { useParams } from 'react-router-dom';

import ExperienceResolver from '../../components/ExperienceResolver';

export const Experience = () => {
  const { uuid } = useParams();

  return uuid && <ExperienceResolver />;
};

export default Experience;
