import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { loadScript } from '../../utils';

const BUILD_URL = process.env.REACT_APP_EMBED_PRODUCT_DEMO_V4;

const ProductDemoV4 = ({ uuid }) => {
  useEffect(() => {
    loadScript(BUILD_URL);
    return () => {};
  }, []);

  return (
    uuid && (
      <Container>
        <div style={{ width: '100%', height: '100%' }}>
          <section
            style={{ width: '100%', height: '100%' }}
            id="irobot-embedded-product-demo-v4"
            data-experience-id={uuid}
            data-build-url={BUILD_URL}
            data-api-url={`${process.env.REACT_APP_API_BASE_URL}/v4`}
            data-show-view-all="true"
          />
        </div>
      </Container>
    )
  );
};

const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
  height: 100%;
`;

ProductDemoV4.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default ProductDemoV4;
