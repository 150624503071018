import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { loadScript } from '../../utils';

const BUILD_URL = process.env.REACT_APP_EMBED_CHART_V3;

const ChartV3 = ({ uuid }) => {
  useEffect(() => {
    loadScript(BUILD_URL);
    return () => {};
  }, []);

  return (
    uuid && (
      <Container>
        <div style={{ width: '100%', height: '100%' }}>
          <section
            style={{ width: '100%', height: '100%' }}
            id="irobot-embedded-comp-chart"
            data-experience-id={uuid}
            data-api-url={`${process.env.REACT_APP_API_BASE_URL}/v3`}
            data-build-url={BUILD_URL}
          />
        </div>
      </Container>
    )
  );
};

const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
`;

ChartV3.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default ChartV3;
