import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
`;

class EmbeddedQuizExperience extends Component {
  loadScript = () => {
    const buildUrl = process.env.REACT_APP_EMBED_QUIZ_CODE_BUILD_URL;
    const script = document.createElement('script');
    script.src = `${buildUrl}/load.js`;
    script.async = true;

    document.body.appendChild(script);
  };

  render() {
    const { uuid } = this.props;
    return (
      <Container>
        <div style={{ width: '100%', height: '100%' }}>
          <section
            style={{ width: '100%', height: '100%' }}
            id="irobot-embed-experience"
            data-quiz-id={uuid}
            data-api-url={process.env.REACT_APP_API_URL_V2}
            data-build-url={process.env.REACT_APP_EMBED_QUIZ_CODE_BUILD_URL}
          />
          {this.loadScript()}
        </div>
      </Container>
    );
  }
}

EmbeddedQuizExperience.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default EmbeddedQuizExperience;
