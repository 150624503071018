import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background-color: #000000;
`;

class EmbeddedProductDemoExperience extends Component {
  componentDidMount() {
    this.updatePageStyles();
  }

  updatePageStyles = () => {
    const body = document.querySelector('body');
    body.style.backgroundColor = '#000000';
    const page = document.querySelector('html');
    page.style.backgroundColor = '#000000';
  };

  loadScript = () => {
    const buildUrl = process.env.REACT_APP_EMBED_PRODUCT_DEMO_CODE_BUILD_URL;
    const script = document.createElement('script');
    script.src = `${buildUrl}/load.js`;
    script.async = true;

    document.body.appendChild(script);
  };

  render() {
    const { uuid } = this.props;
    return (
      <Container>
        <div style={{ width: '100vw', minHeight: '100vh', height: '1px' }}>
          <section
            style={{ width: '100%', height: '100%' }}
            id="irobot-embedded-interactive-experience"
            data-experience-id={uuid}
            data-api-url={process.env.REACT_APP_API_URL}
            data-build-url={
              process.env.REACT_APP_EMBED_PRODUCT_DEMO_CODE_BUILD_URL
            }
          />
          {this.loadScript()}
        </div>
      </Container>
    );
  }
}

EmbeddedProductDemoExperience.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default EmbeddedProductDemoExperience;
