import React from 'react';
import PropTypes from 'prop-types';
import Charts from '../components/comparisonCharts';
import { VERSIONS } from '../constants';

const renderChart = (uuid, version) => {
  let resolvedChart = null;

  switch (version) {
    case VERSIONS.v1:
      resolvedChart = <Charts.ChartV1 uuid={uuid} />;
      break;
    case VERSIONS.v3:
      resolvedChart = <Charts.ChartV3 uuid={uuid} />;
      break;
    case VERSIONS.v4:
      resolvedChart = <Charts.ChartV4 uuid={uuid} />;
      break;

    default:
      resolvedChart = <Charts.ChartV1 uuid={uuid} />;
      break;
  }
  return resolvedChart;
};

export const ComparisonChartResolver = ({ uuid, version }) => (
  <>{renderChart(uuid, version)}</>
);

ComparisonChartResolver.propTypes = {
  uuid: PropTypes.string,
  version: PropTypes.string,
};

ComparisonChartResolver.defaultProps = {
  uuid: '',
  version: '',
};

export default ComparisonChartResolver;
