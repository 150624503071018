import axios from 'axios';

export const fetchData = async (qrCodeUuid, apiVersion = 'v1') => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/${apiVersion}/qr_codes/${qrCodeUuid}/entity`
    );
    if (data) return data;
    return null;
  } catch (e) {
    console.error('Error fetching data', e);
    return null;
  }
};

export const fetchDataV1 = async qrCodeUuid => {
  const data = await fetchData(qrCodeUuid, 'v1');
  return data;
};

export const fetchDataV2 = async qrCodeUuid => {
  const data = await fetchData(qrCodeUuid, 'v2');
  return data;
};
export const fetchDataV3 = async qrCodeUuid => {
  const data = await fetchData(qrCodeUuid, 'v3');
  return data;
};
export const fetchDataV4 = async qrCodeUuid => {
  const data = await fetchData(qrCodeUuid, 'v4');
  return data;
};

export default { fetchData, fetchDataV1, fetchDataV2 };
