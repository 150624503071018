import React from 'react';
import PropTypes from 'prop-types';
import NotFound from '../../screens/NotFound';

export const Redirect = ({ url }) => {
  if (url) {
    window.location = url;
    return null;
  }

  return <NotFound />;
};

Redirect.propTypes = {
  url: PropTypes.string,
};

Redirect.defaultProps = {
  url: '',
};

export default Redirect;
