import { QuizResolver } from './QuizResolver';
import { ComparisonChartResolver } from './ComparisonChartResolver';
import { ProductDemoResolver } from './ProductDemoResolver';
import { LandingPageResolver } from './LandingPageResolver';

export default {
  QuizResolver,
  ComparisonChartResolver,
  ProductDemoResolver,
  LandingPageResolver,
};
