import React from 'react';
import PropTypes from 'prop-types';
import ProductDemos from '../components/productDemos';
import { VERSIONS } from '../constants';

const renderDemo = (uuid, version) => {
  let resolvedChart = null;

  switch (version) {
    case VERSIONS.v1:
      resolvedChart = <ProductDemos.ProductDemoV1 uuid={uuid} />;
      break;
    case VERSIONS.v3:
      resolvedChart = <ProductDemos.ProductDemoV3 uuid={uuid} />;
      break;
    case VERSIONS.v4:
      resolvedChart = <ProductDemos.ProductDemoV4 uuid={uuid} />;
      break;

    default:
      break;
  }
  return resolvedChart;
};

export const ProductDemoResolver = ({ uuid, version }) => (
  <>{renderDemo(uuid, version)}</>
);

ProductDemoResolver.propTypes = {
  uuid: PropTypes.string,
  version: PropTypes.string,
};

ProductDemoResolver.defaultProps = {
  uuid: '',
  version: '',
};

export default ProductDemoResolver;
