import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import './App.css';
import { Experience } from './screens/Experience/Experience';
import Redirect from './components/Redirect';
import { GTM_ID, GTM_ID_TEST } from './config';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_ENV === 'production' ? GTM_ID : GTM_ID_TEST,
};
TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/qr/:uuid" exact>
          <Experience />
        </Route>
        <Route path="/">
          <Redirect url="https://www.irobot.com" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
