import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { loadScript } from '../../utils';

const BUILD_URL = process.env.REACT_APP_EMBED_PRODUCT_DEMO_V1;

const updatePageStyles = () => {
  const body = document.querySelector('body');
  body.style.backgroundColor = '#000000';
  const page = document.querySelector('html');
  page.style.backgroundColor = '#000000';
};

const ProductDemoV1 = ({ uuid }) => {
  useEffect(() => {
    loadScript(BUILD_URL);
    updatePageStyles();
    return () => {};
  }, []);

  return (
    uuid && (
      <Container>
        <div style={{ width: '100vw', minHeight: '100vh', height: '1px' }}>
          <section
            style={{ width: '100%', height: '100%' }}
            id="irobot-embedded-interactive-experience"
            data-experience-id={uuid}
            data-api-url={`${process.env.REACT_APP_API_BASE_URL}/v1`}
            data-build-url={BUILD_URL}
          />
        </div>
      </Container>
    )
  );
};

const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background-color: #000000;
`;

ProductDemoV1.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default ProductDemoV1;
