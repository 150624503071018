import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import isEmpty from 'lodash.isempty';
import lodashCompact from 'lodash.compact';
import lodashJoin from 'lodash.join';
import lodashIsEqual from 'lodash.isequal';
import { EXPERIENCES_TYPE_MAP } from '../../constants';
import { fetchDataV4 as fetchData } from '../../services';

const parseResponse = (experienceData = {}) => {
  const parsedResponseData = {
    entityType: EXPERIENCES_TYPE_MAP[experienceData?.entity_type] || '',
    entityUuid: experienceData?.entity_uuid || '',
    redirectUrl: experienceData?.url_override || '',
    utmParams: experienceData?.query_params || '',
    version: experienceData?.version.toString() || '',
  };
  return parsedResponseData;
};

const getParams = experience => {
  let redirectParams = {};
  const utmParams = {};
  const { query: currentUrlParams } = queryString.parseUrl(
    window.location.href
  );

  if (experience.redirectUrl) {
    const { query: redirectUrlParams } = queryString.parseUrl(
      experience.redirectUrl
    );
    if (!isEmpty(redirectUrlParams)) {
      redirectParams = redirectUrlParams;
    }
  }

  if (!isEmpty(experience?.utmParams)) {
    Object.keys(experience.utmParams).forEach(param => {
      if (experience.utmParams[param]) {
        utmParams[param] = experience.utmParams[param];
      }
    });
  }

  const finalParams = { ...redirectParams, ...utmParams, ...currentUrlParams };
  const shouldRedirect = !lodashIsEqual(currentUrlParams, finalParams);
  return { shouldRedirect, params: finalParams };
};

const attachParams = (url, params) => {
  const { url: urlSection } = queryString.parseUrl(url);
  const mergedQs = queryString.stringify(params);
  const urlArr = [urlSection, mergedQs];
  return lodashJoin(lodashCompact(urlArr), '?');
};

export const connect = WrappedComponent => props => {
  const { uuid } = useParams();
  const [currentExperience, setCurrentExperience] = useState({});

  useEffect(() => {
    async function getData(qRuuid) {
      const response = await fetchData(qRuuid);
      if (response) setCurrentExperience(parseResponse(response));
      return response;
    }
    if (uuid) {
      getData(uuid);
    }
    return () => {};
  }, [uuid]);

  useEffect(() => {
    if (!isEmpty(currentExperience)) {
      const paramsObj = getParams(currentExperience);
      if (currentExperience.redirectUrl) {
        const newUrl = attachParams(
          currentExperience.redirectUrl,
          paramsObj.params
        );
        window.location.replace(newUrl);
      } else if (paramsObj.shouldRedirect) {
        const newUrl = attachParams(window.location.href, paramsObj.params);
        window.location.replace(newUrl);
      }
    }
    return () => {};
  }, [currentExperience]);

  return (
    <WrappedComponent
      version={currentExperience?.version}
      entityType={currentExperience?.entityType}
      entityUuid={currentExperience?.entityUuid}
      {...props}
    />
  );
};

export default connect;
